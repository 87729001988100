import {
    AppBar,
    Container,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Toolbar,
    Fab,
    colors
} from '@material-ui/core';
import { Home, KeyboardArrowUp } from '@material-ui/icons';
import * as React from 'react';
import HideOnScroll from './HideOnScroll';
import { SideDrawer } from './SideDrawer';
import BackToTop from './BackToTop';
import { AuthContext } from '../common/AuthState';
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import WaterfallImage from '../images/Waterfall.svg';

const useStyles = makeStyles({
    navbarDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`
    },
    navListDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `white`
    }
});

const navLinks = [
    { title: `docs`, path: `/docs` },
    { title: `keys`, path: `/keys` },
    { title: `subscription`, path: `/subscriptions` }
];

/**
 * Logout handler needs to be passed into this level otherwise we won't be able to logout! :D
 */
export function Header() {
    const auth = useContext(AuthContext);

    function logoutHandler(): JSX.Element {
        auth.setUnauthStatus();
        return <Redirect to={'/'} />;
    }

    const classes = useStyles();

    return (
        <>
            <HideOnScroll>
                <AppBar position="fixed">
                    <Toolbar component="nav">
                        <Container
                            maxWidth="md"
                            className={classes.navbarDisplayFlex}
                        >
                            <IconButton edge="start" aria-label="home">
                                <a href="/" style={{ color: `white` }}>
                                    <img
                                        src={WaterfallImage}
                                        style={{
                                            height: '50px',
                                            width: '150px',
                                            filter: 'brightness(0) invert(1)'
                                        }}
                                    />
                                </a>
                            </IconButton>

                            <Hidden smDown>
                                <List
                                    component="nav"
                                    aria-labelledby="main navigation"
                                    className={classes.navListDisplayFlex}
                                >
                                    {navLinks.map(({ title, path }) => (
                                        <a
                                            href={path}
                                            key={title}
                                            className={classes.linkText}
                                        >
                                            <ListItem button>
                                                <ListItemText primary={title} />
                                            </ListItem>
                                        </a>
                                    ))}

                                    <a
                                        key="logout"
                                        className={classes.linkText}
                                    >
                                        <ListItem
                                            button
                                            onClick={() => logoutHandler()}
                                        >
                                            <ListItemText primary="logout" />
                                        </ListItem>
                                    </a>
                                </List>
                            </Hidden>
                            <Hidden mdUp>
                                <SideDrawer navLinks={navLinks} />
                            </Hidden>
                        </Container>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar id="back-to-top-anchor" />

            <BackToTop>
                <Fab
                    color="secondary"
                    size="large"
                    aria-label="scroll back to top"
                >
                    <KeyboardArrowUp />
                </Fab>
            </BackToTop>
        </>
    );
}
