import React, { createContext, useState, useEffect } from "react";
import "./index.css";
import { Container } from "@material-ui/core";
import { AppRoutesPublic } from "./AppRoutesPublic";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { AuthContext, getStoredUserAuth, UserAuth } from "./common/AuthState";
import { Header } from "./pages/Header";
import { logout } from "./api/Auth";

export const useAuthHandler = (initialState: UserAuth) => {
    const [auth, setAuth] = React.useState(initialState);
  
    const setAuthStatus = (token: UserAuth) => {
        if(token.token){
            window.localStorage.setItem("token", JSON.stringify(token.token));
            setAuth({
                isAuthenticated: true
            });
        }
    };
  
    const setUnauthStatus = () => {
        const token = window.localStorage.getItem("token");
        if(token){
            logout(token);
            window.localStorage.clear();
            setAuth({
                isAuthenticated: false
            });
        }
    };
  
    return {
      auth,
      setAuthStatus,
      setUnauthStatus
    };
  };

export function App() {
    const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler(
        getStoredUserAuth()
    );

    console.log("jamie " + auth);
    
    return (
        <AuthContext.Provider value={{ auth, setAuthStatus, setUnauthStatus }}>
            <Router>
                <Container>{auth.isAuthenticated ? (
                    <><Header />
                        <AppRoutes /></>
                ) : <AppRoutesPublic />}</Container>
            </Router>
        </AuthContext.Provider>
    );
}
