import { useContext, createContext } from "react";


export interface UserAuth {
    isAuthenticated: boolean,
    token?: string;
}

/** Return user auth from local storage value */
export const getStoredUserAuth = (): UserAuth => {
    const auth = window.localStorage.getItem("token");
    if (auth) {
      return {
        isAuthenticated: true
    };
    }
    return {
        isAuthenticated: false
    };
  };

export interface IAuthContextInterface {
    auth: UserAuth;
    setAuthStatus: (userAuth: UserAuth) => void;
    setUnauthStatus: () => void;
  }
  
  export const AuthContext = createContext<IAuthContextInterface>({
    auth: {
        isAuthenticated: false
    },
    setAuthStatus: () => {},
    setUnauthStatus: () => {}
  });