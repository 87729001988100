import {
    Button,
    makeStyles,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { redactedToken, regenerate, revealFullToken } from '../../api/Keys';
import { Table } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});

export const AdminKeys = () => {
    const classes = useStyles();
    const [token, setToken] = useState({
        token: ''
    });

    function regenerateKeys() {
        const token = window.localStorage.getItem('token');
        if (token) {
            regenerate(token).then((newToken) => {
                if (newToken) {
                    setToken({
                        token: newToken
                    });
                }
            });
        }
    }

    function fullToken() {
        const token = window.localStorage.getItem('token');
        if (token) {
            revealFullToken(token).then((newToken) => {
                if (newToken) {
                    setToken({
                        token: newToken
                    });
                }
            });
        }
    }

    function getRedactedToken() {
        const token = window.localStorage.getItem('token');
        if (token != null) {
            redactedToken(token).then((apiToken) => {
                if (apiToken) {
                    setToken({
                        token: `${apiToken}******`
                    });
                }
            });
        }
    }

    useEffect(() => {
        getRedactedToken();
    }, []); // <-- empty dependency array

    return (
        <>
            {token ? (
                <>
                    <TableContainer
                        component={Paper}
                        style={{ marginTop: '50px' }}
                    >
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>API Key</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key="token">
                                    <TableCell component="th" scope="row">
                                        {token.token}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button onClick={fullToken}>Reveal Token</Button>
                    <Button onClick={regenerateKeys}>Regenerate Tokens</Button>
                </>
            ) : (
                'No tokens yet'
            )}
        </>
    );
};
