import { Container } from '@material-ui/core';
import React from 'react';
import { Header } from '../Header';

export const AdminHome = () => {
    return (
        <Container style={{ marginTop: '50px' }}>
            <div>Here goes some coding...</div>
        </Container>
    );
};
