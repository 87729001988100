import axios from "axios";
import { FormData } from "../pages/Login/Login";

export async function login(data: FormData): Promise<string | undefined> {
    const result = await axios({
        // withCredentials: true,
        method: 'post',
        url: 'https://a1aprir26f.execute-api.eu-west-1.amazonaws.com/dev/login',
        data: {
            username: data.email,
            password: data.password
        }
    });
    console.log(result)
    if(result.data){
        return result.data;
    }
    return undefined;
}

export async function logout(token: string): Promise<string | undefined> {
    const result = await axios({
        // withCredentials: true,
        method: 'post',
        url: 'https://a1aprir26f.execute-api.eu-west-1.amazonaws.com/dev/logout',
        data: token
    });
    console.log(result)
    if(result.data){
        return result.data;
    }
    return undefined;
}