import axios from "axios";

export async function regenerate(token: string): Promise<string | undefined> {
    const result = await axios({
        // withCredentials: true,
        method: 'get',
        url: 'https://a1aprir26f.execute-api.eu-west-1.amazonaws.com/dev/keys',
        headers: {
            Authorization: token
        }
    });
    console.log(result)
    if(result.data){
        return result.data;
    }
    return undefined;
}

export async function redactedToken(token: string): Promise<string | undefined> {
    const result = await axios({
        // withCredentials: true,
        method: 'get',
        url: 'https://a1aprir26f.execute-api.eu-west-1.amazonaws.com/dev/token',
        headers: {
            Authorization: token
        }
    });
    console.log(result)
    if(result.data){
        return result.data;
    }
    return undefined;
}

export async function revealFullToken(token: string): Promise<string | undefined> {
    const result = await axios({
        // withCredentials: true,
        method: 'get',
        url: 'https://a1aprir26f.execute-api.eu-west-1.amazonaws.com/dev/full-token',
        headers: {
            Authorization: token
        }
    });
    console.log(result)
    if(result.data){
        return result.data;
    }
    return undefined;
}