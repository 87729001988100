import { Drawer, IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/icons";
import * as React from "react";
import { useState } from "react";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `black`,
    },
});

interface IPositionSideBar {
    [key: string]: boolean;
}
export function SideDrawer({ navLinks }: any) {

    console.log(navLinks);
    const classes = useStyles();
    const [state, setState] = useState<IPositionSideBar>({ right: false });

    const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ [anchor]: open });
    };

    const sideDrawerList = (anchor: string) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List component="nav">
                {navLinks.map(({ title, path }: any) => (
                    <a href={path} key={title} className={classes.linkText}>
                        <ListItem button>
                            <ListItemText primary={title} />
                        </ListItem>
                    </a>
                ))}
            </List>
        </div>
    );

    return (
        <React.Fragment>
            <IconButton edge="start" aria-label="menu" onClick={toggleDrawer("right", true)}>
                <Menu fontSize="large" style={{ color: `white` }} />
            </IconButton>

            <Drawer anchor="right" open={state.right} onClose={toggleDrawer("right", false)}>
                {sideDrawerList("right")}
            </Drawer>
        </React.Fragment>
    );
};
