import { Redirect, Route, Switch } from "react-router-dom";
import React, { useContext, useState } from "react";

import {  } from "./App";
import { AdminHome } from "./pages/AdminPanel/Home";
import { AdminKeys } from "./pages/AdminPanel/Keys";

/**
 * App Routes for PRIVATE
 * 
 * This is protected from the App index page i.e. it won't hit here
 *
 */
export const AppRoutes = () => {
    const [authState, setAuthState] = useState({});

    
    return (
        <Switch>
            <Route exact path="/" render={() => { return <AdminHome /> }} />
            <Route exact path="/docs" render={() => { return <AdminHome /> }} />
            <Route exact path="/keys" render={() => { return <AdminKeys /> }} />
            <Route exact path="/subscription" render={() => { return <AdminHome /> }} />
            <Route path="/" render={() => <Redirect to="/" />} />
        </Switch>
    );
};
