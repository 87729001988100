import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import React from "react";
import { LoginPage } from "./pages/Login/Login";

/**
 * App Routes for Public pages
 * i.e. where a user can go when they're not authenticated
 *
 * - Redirect to /login
 */

 export const AppRoutesPublic = withRouter((props: RouteComponentProps) => {
    return (
        <Switch>
            <Route exact path="/login" render={() => { return <LoginPage /> }} />
            <Route path="/" render={() => <Redirect to="/login" />} />
        </Switch>
    )
});
