import {
    makeStyles,
    Container,
    Grid,
    TextField,
    Button
} from '@material-ui/core';
import React, { useState } from 'react';
import { login } from '../../api/Auth';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../common/AuthState';
import WaterfallImage from '../../images/Waterfall.svg';

export interface FormData {
    email: string;
    password: string;
}

export function LoginPage(): JSX.Element {
    const auth = React.useContext(AuthContext);
    const useStyles = makeStyles((theme) => ({
        container: {
            padding: theme.spacing(3),
            marginTop: '100px'
        }
    }));

    const { handleSubmit, register } = useForm<FormData>();

    const classes = useStyles();

    const submit = async (data: FormData) => {
        //event.preventDefault(); // Stops you doing this from Console
        const responseData = await login(data);
        alert(responseData);
        if (responseData) {
            alert('Logged in');
            auth.setAuthStatus({ isAuthenticated: true, token: responseData });
        }
    };

    return (
        <>
            <img
                src={WaterfallImage}
                style={{
                    height: '250px',
                    width: '350px'
                }}
            />
            <Container className={classes.container} maxWidth="xs">
                <form onSubmit={handleSubmit(submit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        inputRef={register}
                                        label="Email"
                                        name="email"
                                        size="small"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        inputRef={register}
                                        label="Password"
                                        name="password"
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                fullWidth
                                type="submit"
                                variant="contained"
                            >
                                Log in
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </>
    );
}
